/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Obchody"}>
        <SiteHeader />

        <Column className="css-1ijhuvf --style2 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":1390,"backgroundColor":"rgba(63,25,246,1)"}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"5"} style={{"maxWidth":1425}}>
              
              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"Obchody<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(35, 134, 233);\">Tipsport</span>"}>
              </Title>

              <Text className="text-box" content={"Otevírací doba:<br>Po-Čt : 9-17:30<br>Pá 9-18<br>Od 13-13:45 polední pauza<br>So-Ne : 9-14<br>Tel.: 702 012 634<br>"}>
              </Text>

              <Button className="btn-box" url={"https://m.tipsport.cz/"} href={"https://m.tipsport.cz/"} content={"Tipsport&nbsp;"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(44, 140, 236);\">Šaty za hubičku</span>"}>
              </Title>

              <Text className="text-box" content={"Tel.: 608 158 053<br>Email.: info@satyzahubicku.cz<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.satyzahubicku.cz/"} href={"https://www.satyzahubicku.cz/"} content={"Šaty za hubičku&nbsp;"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(43, 142, 242);\">Květinové Tlapičky</span>"}>
              </Title>

              <Text className="text-box" content={"Květinářství a chovatelské potřeby pro zvířatka<br>Otevírá doba:<br>Po-Čt : 9-18<br>Pá : 9-17<br>Tel.: 736 775 271<br>Email.: Ani02@seznam.cz<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.facebook.com/kvetinovetlapky/?notif_id=1687967358468264&notif_t=follower_invite&ref=notif"} href={"https://www.facebook.com/kvetinovetlapky/?notif_id=1687967358468264&notif_t=follower_invite&ref=notif"} content={"Květinové Tlapičky&nbsp;"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(42, 138, 236);\">Hodinářství Mann Vladimír&nbsp;</span>"}>
              </Title>

              <Text className="text-box" content={"Otevírací doba:<br>Po-Čt : 9-17<br>Od 12-13 polední přestávka&nbsp;<br>Tel.: 739 260 825<br>Email.: mann.v@seznam.cz<br>"}>
              </Text>

              <Button className="btn-box" url={"https://vladimir-mann.business.site/"} href={"https://vladimir-mann.business.site/"} content={"Hodinářství&nbsp;"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(36, 138, 239);\">Zlatnictví Michaela Zímová</span>"}>
              </Title>

              <Text className="text-box" content={"Otevírací doba:<br>Po-Čt : 10-17:30<br>Pá : 10-16<br>Tel.: 723 975 058<br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(42, 139, 236);\">Ukrajinské speciality ,,CMAK\"</span>"}>
              </Title>

              <Text className="text-box" content={"Otevírací doba:<br>Po-Út : 13-19:30<br>St-Pá : 10-19:30<br>So-Ne : 11-19:30<br>Tel.: 608 423 721<br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(53, 145, 238);\">Potraviny Modrý pavilon</span>"}>
              </Title>

              <Text className="text-box" content={"Otevírací doba:<br>Po-Pá : 8-22<br>Tel.: 773 919 889<br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} parallax={false}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(44, 143, 243);\">Second hand</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: rgb(139, 144, 149);\">Otevírací doba:<br>Po-Pá : 9-18<br>So : 8-12<br>Tel.: 261 711 353<br>Email.:&nbsp;info@second-hand-textil.cz</span><span style=\"color: rgb(55, 145, 235);\"><br></span>"}>
              </Text>

              <Button className="btn-box" url={"https://www.second-hand-textil.cz/"} href={"https://www.second-hand-textil.cz/"} content={"Second hand"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(51, 146, 241);\">Deejada</span>"}>
              </Title>

              <Text className="text-box" content={"Dekorace a doplňky<br>Internetový obchod a výdejna<br>Otevírací doba:<br>Po-Pá : 8-14<br>Tel.: 606 143 011<br>Email.: info@deejada.cz<br><br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.deejada.cz/?gclid=Cj0KCQjwtO-kBhDIARIsAL6LorfdTkDAiw8l5HuKV0M0oKZBylhti-cFH4drtXYiaBPpmeCPPmSToMwaAr8DEALw_wcB"} href={"https://www.deejada.cz/?gclid=Cj0KCQjwtO-kBhDIARIsAL6LorfdTkDAiw8l5HuKV0M0oKZBylhti-cFH4drtXYiaBPpmeCPPmSToMwaAr8DEALw_wcB"} content={"Deejada.cz"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"vqdin1gv0zo"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(40, 140, 238);\">Zázvoruj</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Internetový obchod&nbsp;<br>Tel.: 777 702 304<br>Email.: zazvoruj@zazvoruj.cz<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.zazvoruj.cz/"} href={"https://www.zazvoruj.cz/"} content={"Zázvoruj&nbsp;"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"o4rkq7ba6wo"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(24, 129, 235);\">Zirosi</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Internetový obchod s kabelkami<br>Tel.: 778 053 005<br>Email.: info@zirosi.cz<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.zirosi.cz/"} href={"https://www.zirosi.cz/"} content={"Zirosi"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"Modrý pavilon"}>
              </Title>

              <Text className="text-box" content={"+420 739 090 844<br>ocmodrypavilon@seznam.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}